import cogoToast from 'cogo-toast';
const { createSlice } = require('@reduxjs/toolkit');
const { fetchProduct, fetchProductLimit, fetchProductId, fetchCategoryId, fetchSearchProduct } = require('../Action/ProductsAction');

const initialState = {
    products: [],
    productID:{},
    searche:[]
}
const productSlice = createSlice({
    name: 'product',
    initialState,
    reducers: {
        setProducts(state, action) {
            state.products = action.payload;
        },
        clearSearchData(state){
          state.searche = [];
        }
    },
    extraReducers: (builder) => {
        builder
          .addCase(fetchProduct.pending, (state) => {
            state.status = 'loading';
          })
          .addCase(fetchProduct.fulfilled, (state, action) => {
            state.status = 'succeeded';
            if(action.payload?.error === 500) cogoToast.error( "Server not Found!", {position: "top-right"});
            if(action.payload.length === 0 ) cogoToast.info( "Product empty in db or error, please contact the site administrators!", {
              position: "top-right",
              hideAfter: 10
            });
            state.products = action.payload;
          })
          .addCase(fetchProduct.rejected, (state, action) => {
            state.status = 'failed';
            state.error = action.error.message;
            cogoToast.error( state.error, {position: "top-right"});
          })
          .addCase(fetchProductLimit.pending, (state) => {
            state.status = 'loading';
          })
          .addCase(fetchProductLimit.fulfilled, (state, action) => {
            state.status = 'succeeded';
            if(action.payload?.error === 500) cogoToast.error( "Server not Found!", {position: "top-right"});
            if(action.payload.length === 0 ) cogoToast.info( "Product empty in db or error, please contact the site administrators!", {
              position: "top-right",
              hideAfter: 10
            });
            state.products = action.payload.data;
            state.count = action.payload.count;
          })
          .addCase(fetchProductLimit.rejected, (state, action) => {
            state.status = 'failed';
            state.error = action.error.message;
            cogoToast.error( state.error, {position: "top-right"});
          })
          .addCase(fetchProductId.pending, (state) => {
            state.status = 'loading';
          })
          .addCase(fetchProductId.fulfilled, (state, action) => {
            state.status = 'succeeded';
            state.productID = action.payload;
          })
          .addCase(fetchProductId.rejected, (state, action) => {
            state.status = 'failed';
            state.error = action.error.message;
            cogoToast.error( state.error, {position: "top-right"});
          })
          .addCase(fetchCategoryId.pending, (state) => {
            state.statuscat = 'loading';
          })
          .addCase(fetchCategoryId.fulfilled, (state, action) => {
            state.statuscat = 'succeeded';
            state.products = action.payload;
          })
          .addCase(fetchCategoryId.rejected, (state, action) => {
            state.statuscat = 'failed';
            state.error = action.error.message;
            cogoToast.error( state.error, {position: "top-right"});
          })
          .addCase(fetchSearchProduct.pending, (state) => {
            state.status = 'loading';
          })
          .addCase(fetchSearchProduct.fulfilled, (state, action) => {
            state.status = 'succeeded';
            state.searche = action.payload;
          })
          .addCase(fetchSearchProduct.rejected, (state, action) => {
            state.status = 'failed';
            state.error = action.error.message;
            cogoToast.error( state.error, {position: "top-right"});
          })
      },
});

export const selectAllProduct = (state) => state.product;
export const productCount = (state) => state.product;
export const setStatusRequest = (state) => state.product;
//export const getProductID = (state) => state.product.productID;

export const { setProducts, setProductToCategory, clearSearchData } = productSlice.actions;
export default productSlice.reducer;
